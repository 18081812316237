import { useState } from "react";
//import { storage } from "../firebase/config";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const useFireStorage = () => {
  const [imageUrl, setImageUrl] = useState("");

  const uploadFile = async (file, folder) => {
    return new Promise((resolve) => {
      const today = new Date();
      const uploadPath = `${folder}/${new Date().valueOf()}.jpg`;

      const storage = getStorage();
      const starsRef = ref(storage, uploadPath);

      uploadBytes(starsRef, file).then((value) => {
        getDownloadURL(value.ref).then((url) => {
          //setImageUrl(url);
          resolve(url);
        });
      });
    });
  };

  return { uploadFile, imageUrl };
};
