import React, { useState, useEffect } from "react";
import axios from "axios";
import { projectFirestore } from "../../firebase/config";
import ReactPaginate from "react-paginate";
import { getDocs, collection, deleteDoc, doc } from "firebase/firestore";
import Adapter from "./Adapter";

//let PageSize = 10;

export default function Test() {
  const [data, setData] = useState([]);
  const [record, setRecords] = useState([]);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 6;

  // window.addEventListener("load", () => {
  //   Fetchdata();
  // });

  useEffect(() => {
    projectFirestore
      .collection("project")
      .where("type", "==", "android")
      .orderBy("count", "desc")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((element) => {
          var data = element.data();
          setData((arr) => [...arr, data]);
          setRecords((arr) => [...arr, data]);
        });
      });
  }, []);

  const Filter = (value) => {
    setRecords(data.filter((f) => f.name.toLowerCase().includes(value)));
    console.log(record);
  };

  return (
    <>
      <div>
        <input
          type="text"
          className="form-control"
          onChange={(e) => Filter(e.target.value)}
        />
        <Adapter data={record} />
      </div>
    </>
  );
}
