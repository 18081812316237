import "./Popup.css";
import React from "react";

const Popup = ({ clickedVideo, setClickedVideo }) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setClickedVideo(null);
    }
  };

  return (
    <>
      <div className="overlay dismiss" onClick={handleClick}>
        {/* <img src={clickedImg} alt="bigger pic" /> */}
        <iframe
          width="1920"
          height="1080"
          src={"https://www.youtube.com/embed/" + clickedVideo}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <span className="dismiss" onClick={handleClick}>
          X
        </span>
      </div>
    </>
  );
};

export default Popup;
