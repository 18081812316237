import React, { useState } from "react";
import { useTheme } from "../hooks/useTheme";
import Popup from "./Popup";

// styles
import "./RecipeList.css";

export default function HomeList({ projects }) {
  const { mode, color } = useTheme();
  const [clickedVideo, setClickedVideo] = useState(null);

  if (projects.length === 0) {
    return <div className="error">ไม่มีข้อมูลโปรเจค...</div>;
  }

  return (
    <div className="recipe-list">
      {projects.map((project, i) => (
        <div key={i} className={`card ${mode}`}>
          <img style={{ width: "100%" }} src={project.photo} alt="image" />
          <h4>{project.name}</h4>
          <button
            onClick={() =>
              project.type === "web"
                ? window.open(project.youtube_id, "_blank").focus()
                : project.youtube_id !== ""
                ? setClickedVideo(project.youtube_id)
                : console.log("emtry")
            }
            style={{ background: color }}
          >
            {project.type === "web" ? "ดูเว็บไซต์" : "ดูวิดีโอ"}
          </button>
        </div>
      ))}

      {clickedVideo && (
        <Popup clickedVideo={clickedVideo} setClickedVideo={setClickedVideo} />
      )}
    </div>
  );
}
