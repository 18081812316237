import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBHphJTHnxsHLA5x0w3GF7dS83tb-9IKq8",
  authDomain: "tanat29myproject-946f3.firebaseapp.com",
  projectId: "tanat29myproject-946f3",
  storageBucket: "tanat29myproject-946f3.appspot.com",
  messagingSenderId: "330058176320",
  appId: "1:330058176320:web:fd3b4980ce5a8cecd08ca5",
};

// init firebase
const app = initializeApp(firebaseConfig);

// init services
const projectFirestore = getFirestore(app);
const projectStorage = getStorage(app);

export { projectFirestore, projectStorage };
