import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../hooks/useTheme";
import Logo from "../assets/logo2.png";
import Menu from "../assets/menu.svg";

// styles
import "./Navbar.css";

export default function Navbar() {
  const { color } = useTheme();
  const [showpopup, setShowpopup] = useState(false);

  return (
    <div className="navbar" style={{ background: color }}>
      <nav>
        <Link
          to="/"
          className="brand"
          style={{ background: color, marginLeft: "0" }}
        >
          <img height="50" src={Logo} alt="logo" />
        </Link>

        <img className="test" src={Menu} onClick={() => setShowpopup(true)} />

        <div className="hideNavbar" style={{ float: "right" }}>
          <Link to="/" className="brand">
            Youtube
          </Link>
          <Link to="/android" className="brand">
            Android
          </Link>
          <Link to="/ar" className="brand">
            AR
          </Link>
          <Link to="/ar_filter" className="brand">
            AR Filter
          </Link>
          <Link to="/game" className="brand">
            Game
          </Link>
          <Link to="/flutter" className="brand">
            Flutter
          </Link>
          <Link to="/web" className="brand">
            Web
          </Link>
          <Link to="/contact" className="brand">
            ติดต่อฉัน
          </Link>
        </div>
      </nav>

      <div
        className="test"
        style={{
          width: "100%",
          display: showpopup === false ? "none" : "grid",
          background: "white",
          float: "right",
          justifyContent: "center",
          position: "absolute",
          zIndex: "100",
        }}
      >
        <Link
          to="/"
          style={{ textAlign: "center", color: "#333" }}
          onClick={() => setShowpopup(false)}
        >
          Android
        </Link>
        <Link
          to="/ar"
          style={{ textAlign: "center", color: "#333" }}
          onClick={() => setShowpopup(false)}
        >
          AR
        </Link>
        <Link
          to="/ar_filter"
          style={{ textAlign: "center", color: "#333" }}
          onClick={() => setShowpopup(false)}
        >
          AR Filter
        </Link>
        <Link
          to="/game"
          style={{ textAlign: "center", color: "#333" }}
          onClick={() => setShowpopup(false)}
        >
          Game
        </Link>
        <Link
          to="/flutter"
          style={{ textAlign: "center", color: "#333" }}
          onClick={() => setShowpopup(false)}
        >
          Flutter
        </Link>
        <Link
          to="/web"
          style={{ textAlign: "center", color: "#333" }}
          onClick={() => setShowpopup(false)}
        >
          Web
        </Link>
        <Link
          to="/contact"
          style={{ textAlign: "center", color: "#333" }}
          onClick={() => setShowpopup(false)}
        >
          ติดต่อฉัน
        </Link>
        <span
          style={{
            position: "absolute",
            top: "5px",
            right: "15px",
            color: "black",
            cursor: "pointer",
          }}
          onClick={() => setShowpopup(false)}
        >
          X
        </span>
      </div>
    </div>
  );
}
