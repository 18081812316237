import { useState, useEffect } from "react";
import { projectFirestore } from "../../firebase/config";
import RecipeList from "../../components/RecipeList";
import Adapter from "./Adapter";
import { getDocs, collection, where, orderBy, query } from "firebase/firestore";
import "./Home.css";

export default function AR() {
  const [isPending, setIsPending] = useState(false);
  const [data, setData] = useState([]);

  useEffect(async () => {
    setIsPending(true);

    const q = query(
      collection(projectFirestore, "project"),
      where("type", "==", "ar"),
      orderBy("count", "desc")
    );

    const querySnapshot = await getDocs(q);
    let results = [];
    querySnapshot.forEach((doc) => {
      results.push({
        ...doc.data(),
        id: doc.id,
      });
    });
    setData(results);
    setIsPending(false);

    return querySnapshot;
  }, []);

  return (
    <div className="home">
      {isPending && <p className="loading">Loading...</p>}
      <h3>AR</h3>
      <Adapter data={data} />
    </div>
  );
}
