import QRCode from "../../assets/qr_code.png";
import Profile from "../../assets/profile.JPG";
import { useTheme } from "../../hooks/useTheme";
import "./Contact.css";

export default function Contact() {
  const { mode } = useTheme();
  return (
    <div className={`recipe ${mode}`}>
      <h3 style={{ textAlign: "center", margin: "20px auto" }}>ติดต่อฉัน</h3>

      <img
        src={Profile}
        height={250}
        alt="qr code"
        style={{
          display: "block",
          margin: "auto",
          borderRadius: "50%",
          paddingBottom: "10px",
        }}
      ></img>
      <div
        style={{
          textAlign: "left",
          maxWidth: "850px",
          margin: "auto",
          paddingBottom: "10px",
        }}
      >
        <h3 style={{ textAlign: "left" }}>โปรไฟล์</h3>

        <p>
          ผมชื่อ "ธนัท มงคลบริรักษ์" นะครับ
          จบจากสถาบันเทคโนโลยีเจ้าคุณทหารลาดพระบัง ในสาขาวิศวกรรมสารสนเทศ
          ประสบการณ์ด้าน Programming 10 ปี มีความสารถในการทำโปรเจค Android ,
          Flutter , AR , AR Filter , Godot , Unity , React อยู่ในระดับดี
          รับงานมาแล้วมากกว่า 200 งาน
        </p>

        <p>
          ปัจจุบันทำงานเป็นโปรแกรมเมอร์ฟรีแลนซ์
          ดูแลงานและให้คำปรึกษาให้ลูกค้ามากมาย ทั้งลูกค้าทั่วไปและลูกค้าบริษัท
        </p>

        <p>รับสอนผ่าน TeamViewer , Zoom และอัดวิดีโอสอน</p>
      </div>

      <img
        src={QRCode}
        height={450}
        style={{ display: "block", margin: "auto", paddingBottom: "10px" }}
        alt="qr code"
      ></img>

      <div
        style={{
          textAlign: "left",
          maxWidth: "850px",
          margin: "auto",
        }}
      >
        <h3 style={{ textAlign: "left" }}>ขั้นตอนการรับงาน</h3>

        <p>1. คุยรายละเอียดงานโดยรวมและตกลงราคากันตามขอบเขตที่ได้รับมอบหมาย</p>
        <p>2. เขียนใบเสนอราคาและทำการมัดจำก่อนเริ่มงาน</p>
        <p>3. ออกแบบดีไซน์หรือรายละเอียดตามที่ลูกค้าต้องการ</p>
        <p>4. เริ่มทำงานและจะรายงานความคืบหน้าของงานเป็นช่วงๆ</p>
        <p>5. ตรวจงานรายละเอียดของงานโดยรวม และถ่ายคลิปการนำเสนอส่งให้ลูกค้า</p>
        <p>
          6. เมื่อลูกค้าพอใจกับผลงานนั้นๆแล้ว
          ทำการจ่ายค่าจ้างที่เหลือและส่งผลงานทันที
        </p>
        <p>7. เมื่องานเสร็จสมบูรณ์แล้วก็เป็นอันปิดจ๊อบนั้น</p>

        <p>
          ปล. การแก้งานหลังจากเสร็จงานไปแล้วสามารถทำได้
          ส่วนเรื่องราคานั้นขึ้นอยู่กับรายละเอียดของงานที่แก้ว่ามากน้อยแค่ไหน
          ถ้าแก้ไขนิดเดียวจะไม่คิดราคาเพิ่ม
          แต่ถ้าแก้ไขเยอะก็จำเป็นจะต้องคิดราคาเพิ่มตามสัดส่วน
          ซึ่งแนะนำว่าให้คุยกับทางทีมงานโดยตรงครับ
        </p>
        <br />
        <h3 style={{ textAlign: "left" }}>ติดต่อทีมงาน</h3>

        <p>
          1. Facebook Page :{" "}
          <a href="https://www.facebook.com/androidproject1/">คลิกที่นี่</a>{" "}
        </p>
        <p>2. Line : @pku4935d</p>
        <p>3. Tel : 092–635–0088</p>
        <br />
        <h3 style={{ textAlign: "left" }}>คุณจะได้รับอะไร</h3>

        <p>- ทางลูกค้าจะได้รับไฟล์งานหรือไฟล์โปรเจคที่จะสามารถรันลงระบบได้</p>
        <p>
          - ถ้าลูกค้าต้องให้สอนหรืออธิบายโค้ดสามารถโทรศัพท์ติดต่อสอบถามได้เลยทาง
          หรือสอบถามทาง Line ได้
        </p>
        <p>- ให้คำปรึกษากับลูกค้าได้ตลอด</p>
      </div>
    </div>
  );
}
