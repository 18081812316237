import React, { useState, useEffect } from "react";
import { projectFirestore } from "../../firebase/config";
import { useTheme } from "../../hooks/useTheme";
import PopupMenu from "../../components/PopupMenu";
import { getDocs, collection, where, orderBy, query } from "firebase/firestore";
import AdapterHome from "./AdapterHome";
import "./Home.css";

export default function Home() {
  const { color } = useTheme();
  const [isPending, setIsPending] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      console.log("test");
      setIsPending(true);

      const q = query(
        collection(projectFirestore, "project"),
        where("type", "==", "youtube"),
        orderBy("count", "desc")
      );

      const querySnapshot = await getDocs(q);
      let results = [];
      querySnapshot.forEach((doc) => {
        results.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      setData(results);
      setIsPending(false);

      // const q = query(
      //   collection(projectFirestore, "project"),
      //   where("type", "==", "android"),
      //   orderBy("count", "desc")
      // );

      // const unsubscribe = onSnapshot(q, (snapshot) => {
      //   let results = [];
      //   snapshot.docs.map((doc) =>
      //     results.push({
      //       ...doc.data(),
      //       id: doc.id,
      //     })
      //   );
      //   setData(results);
      //   setIsPending(false);
      // });
    }

    fetchData();
  }, []);

  return (
    <div className="home">
      {isPending && <p className="loading">Loading...</p>}
      <h3>Youtube</h3>
      <AdapterHome data={data} />
    </div>
  );
}
