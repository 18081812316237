import "./Loading.css";

export default function Loading() {
  return (
    <>
      <div style={{ display: "flex" }} className="modal">
        <div className="modal-content">
          <div className="loader"></div>
          <div className="modal-text">กรุณารอสักครู่...</div>
        </div>
      </div>
    </>
  );
}
