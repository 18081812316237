import { projectFirestore } from "../../firebase/config";
import { useState } from "react";

export default function Test3() {
  const [info, setInfo] = useState([]);

  window.addEventListener("load", () => {
    Fetchdata();
  });

  const Fetchdata = () => {
    console.log("Test");
    projectFirestore
      .collection("project")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((element) => {
          var data = element.data();
          setInfo((arr) => [...arr, data]);
        });
      });
  };

  return (
    <div>
      <center>
        <h2>Student Details</h2>
      </center>

      {info.map((data) => (
        <Frame course={data.id} name={data.name} />
      ))}
    </div>
  );
}

// Define how each display entry will be structured
const Frame = ({ course, name }) => {
  return (
    <center>
      <div className="div">
        <p>NAME : {name}</p>

        <p>Course : {course}</p>
      </div>
    </center>
  );
};
