import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useTheme } from "./hooks/useTheme";

// page components
import Navbar from "./components/Navbar";
import Home from "./pages/home/Home";
import Android from "./pages/home/Android";
import Test from "./pages/home/Test";
import Test3 from "./pages/home/Test3";
import AR from "./pages/home/AR";
import ARFilter from "./pages/home/ARFilter";
import Game from "./pages/home/Game";
import Flutter from "./pages/home/Flutter";
import Web from "./pages/home/Web";
import Create from "./pages/create/Create";
import Footer from "./pages/Footer";
import ThemeSelector from "./components/ThemeSelector";

// styles
import "./App.css";
import Contact from "./pages/contact/Contact";

function App() {
  const { mode } = useTheme();

  return (
    <div className={`App ${mode}`}>
      <BrowserRouter>
        <Navbar />
        <ThemeSelector />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/android" element={<Android />} />
          <Route path="/test" element={<Test />} />
          <Route path="/test3" element={<Test3 />} />
          <Route path="/ar" element={<AR />} />
          <Route path="/ar_filter" element={<ARFilter />} />
          <Route path="/game" element={<Game />} />
          <Route path="/flutter" element={<Flutter />} />
          <Route path="/web" element={<Web />} />
          <Route path="/add" element={<Create />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
