import { useState, useEffect } from "react";
import { projectFirestore } from "../../firebase/config";
import RecipeListWeb from "../../components/RecipeListWeb";
import { useTheme } from "../../hooks/useTheme";
import {
  getDocs,
  collection,
  where,
  orderBy,
  query,
  limit,
} from "firebase/firestore";
import "./Home.css";

export default function Web() {
  const { color } = useTheme();
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [max, setMax] = useState(1);
  const limitPage = 12;

  useEffect(() => {
    async function getProject() {
      setIsPending(true);
      setCurrentPage(1);
      setIsPending(true);

      const q = query(
        collection(projectFirestore, "project"),
        where("type", "==", "web"),
        orderBy("count", "desc")
      );

      const q2 = query(
        collection(projectFirestore, "project"),
        where("type", "==", "web"),
        orderBy("count", "desc"),
        limit(limitPage)
      );

      const querySnapshot = await getDocs(q);
      const querySnapshot2 = await getDocs(q2);
      let results = [];
      querySnapshot.forEach((doc) => {
        results.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      setData(results);
      setIsPending(false);

      if (querySnapshot2.size === 0) {
        setMax(1);
      } else if (querySnapshot2.size % limitPage !== 0) {
        setMax(Math.ceil(querySnapshot2.size / limitPage));
      } else if (querySnapshot2.size % limitPage === 0) {
        setMax(querySnapshot2.size / limitPage);
      }
    }

    getProject();
  }, []);

  const showNext = ({ item }) => {
    const fetchNextData = async () => {
      const res = projectFirestore
        .collection("project")
        .where("type", "==", "web")
        .orderBy("count", "desc");

      res
        .limit(limitPage)
        .startAfter(item.count)
        .onSnapshot(
          (snapshot) => {
            if (snapshot.empty) {
              setError("No Data");
              setIsPending(false);
            } else {
              let results = [];
              snapshot.docs.forEach((doc) => {
                // console.log(doc)
                results.push({ ...doc.data(), id: doc.id });
              });
              setData(results);
              setIsPending(false);
              setCurrentPage(currentPage + 1);
            }
          },
          (err) => {
            setError(err.message);
            setIsPending(false);
          }
        );
    };
    fetchNextData();
  };

  const showPrevious = ({ item }) => {
    const fetchPreviousData = () => {
      const res = projectFirestore
        .collection("project")
        .where("type", "==", "web")
        .orderBy("count", "desc")
        .endBefore(item.count)
        .limitToLast(limitPage);
      res.onSnapshot(function (querySnapshot) {
        const items = [];
        querySnapshot.forEach(function (doc) {
          items.push({ key: doc.id, ...doc.data() });
        });
        setData(items);
        setCurrentPage(currentPage - 1);
      });
    };
    fetchPreviousData();
  };

  return (
    <div className="home">
      {error && <p className="error">{error}</p>}
      {isPending && <p className="loading">Loading...</p>}
      <h3>Web</h3>
      {data && <RecipeListWeb projects={data} />}
      <div style={{ display: "flex", justifyContent: "center" }}>
        {currentPage === 1 ? (
          <button style={{ background: color }} disabled>
            Previous
          </button>
        ) : (
          <button
            style={{ background: color }}
            onClick={() => showPrevious({ item: data[0] })}
          >
            Previous
          </button>
        )}

        {currentPage === max ? (
          <button disabled style={{ marginLeft: "5px", background: color }}>
            Next
          </button>
        ) : (
          <button
            style={{ marginLeft: "5px", background: color }}
            onClick={() => showNext({ item: data[data.length - 1] })}
          >
            Next
          </button>
        )}

        <p style={{ marginLeft: "5px", marginTop: "5px", fontSize: "0.9em" }}>
          Page {currentPage} - {max}
        </p>
      </div>
    </div>
  );
}
