import { useState, useEffect } from "react";
import { useTheme } from "../hooks/useTheme";
import modeIcon from "../assets/mode-icon.svg";
import { projectFirestore } from "../firebase/config";
import {
  doc,
  getDoc,
  onSnapshot,
  collection,
  where,
  orderBy,
  query,
} from "firebase/firestore";
import "./ThemeSelector.css";

const themeColors = ["#58249c", "#249c6b", "#b70233"];

export default function ThemeSelector() {
  const { changeColor, changeMode, mode } = useTheme();
  const [job, setJob] = useState(0);

  const toggleMode = () => {
    changeMode(mode === "dark" ? "light" : "dark");
  };
  console.log(mode);

  useEffect(async () => {
    const ref = doc(projectFirestore, "data", "tvuxx7FY6ktujIpdEJku");
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      setJob(docSnap.data()["job"]);
    }
  }, []);

  return (
    <div className="theme-selector">
      <div className="mode-toggle">
        <img
          onClick={toggleMode}
          src={modeIcon}
          style={{ filter: mode === "dark" ? "invert(100%)" : "invert(20%)" }}
          alt="dark/light toggle icon"
        />
        <h4>คิวที่รับอยู่ {job} งาน</h4>
      </div>

      <div className="theme-buttons">
        {themeColors.map((color) => (
          <div
            key={color}
            onClick={() => changeColor(color)}
            style={{ background: color }}
          />
        ))}
      </div>
    </div>
  );
}
