import { useState } from "react";
import { projectFirestore } from "../../firebase/config";
import Loading from "../../components/Loading";
import Select from "react-select";
import {
  getDocs,
  doc,
  setDoc,
  addDoc,
  collection,
  orderBy,
  query,
  onSnapshot,
  limit,
} from "firebase/firestore";
import { useFireStorage } from "../../hooks/useFireStorage";
// styles
import "./Create.css";

const categories = [
  { value: "android", label: "Android" },
  { value: "ar", label: "AR" },
  { value: "ar_filter", label: "AR_Filter" },
  { value: "game", label: "Game" },
  { value: "flutter", label: "Flutter" },
  { value: "web", label: "Web" },
  { value: "youtube", label: "Youtube" },
];

export default function Create() {
  const [name, setName] = useState("");
  const [youtudeId, setYoutudeId] = useState("");
  const [type, setType] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailError, setThumbnailError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { uploadFile } = useFireStorage();

  function getCount() {
    return new Promise((resolve) => {
      const q = query(
        collection(projectFirestore, "project"),
        orderBy("count", "desc"),
        limit(1)
      );
      onSnapshot(q, (querySnapshot) => {
        const urls = querySnapshot.docs.map((d) => ({ id: d.id, ...d.data() }));
        resolve(urls[0].count);
      });
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      var photo1 = await uploadFile(thumbnail, type.label);

      const ref = collection(projectFirestore, "project");
      const { id } = await addDoc(ref, {});

      await setDoc(doc(projectFirestore, "project", id), {
        count: (await getCount()) + 1,
        id: id,
        name: name,
        youtube_id: youtudeId,
        photo: photo1,
        type: type.value,
      }).then(() => {
        setIsLoading(false);
        //history.push("/");
        setName("");
        setYoutudeId("");
        setThumbnail(null);
      });
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleFileChange = (e) => {
    setThumbnail(null);
    let selected = e.target.files[0];
    console.log(selected);

    if (!selected) {
      setThumbnailError("Please select a file");
      return;
    }
    if (!selected.type.includes("image")) {
      setThumbnailError("Selected file must be an image");
      return;
    }

    setThumbnailError(null);
    setThumbnail(selected);
    console.log("thumbnail updated");
  };

  return (
    <div className="create">
      {isLoading && <Loading />}
      <h2 className="page-title">เพิ่มโปรเจค</h2>
      <form onSubmit={handleSubmit}>
        <label>
          <span>ชื่อโปรเจค:</span>
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
            required
          />
        </label>

        <label>
          <span>Youtube Id:</span>
          <input
            type="text"
            onChange={(e) => setYoutudeId(e.target.value)}
            value={youtudeId}
          />
        </label>

        <label>
          <span>Project type:</span>
          <Select onChange={(option) => setType(option)} options={categories} />
        </label>

        <label>
          <span>Profile :</span>
          <input accept="image/*" type="file" onChange={handleFileChange} />
          {thumbnailError && <div className="error">{thumbnailError}</div>}
        </label>

        <button className="btn">submit</button>
      </form>
    </div>
  );
}
